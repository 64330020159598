import { NextPage, GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { dehydrate, QueryClient } from "react-query";
import { login } from "src/api/auth/login";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import { queryKey as getSessionDataKey, getSessionData } from "src/api/auth/getSessionData";
import { SESSION_COOKIE_KEY } from "src/constants/global";
import useSessionData, { useSessionUserType, ESessionUserType } from "src/hooks/useSessionData";
import Page from "src/layouts/Page";

interface IProps {}

interface IQuery {
  familyToken: string;
  familyId: string;
  userId: string;
}

const Login: NextPage<IProps> = () => {
  const router = useRouter();
  const sessionData = useSessionData();
  const sessionUserType = useSessionUserType();

  useEffect(() => {
    if (sessionUserType !== ESessionUserType.Unknown && sessionData && sessionData.data) {
      if (!sessionData.data.attributes?.onboarding_completed_at) {
        router.replace(`/${sessionUserType}/on-boarding/welcome`);
      } else {
        router.replace(`/${sessionUserType}/homepage`);
      }
    }
  }, [sessionUserType, router, sessionData]);

  return (
    <Page>
      <h1 className="my-auto px-7 text-center font-myriad text-h1 font-bold text-secondary-dark-grey">
        You are not logged in, please visit your unique link to gain access
      </h1>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<IProps> = async context => {
  const { req, res, query } = context;
  const queryClient = new QueryClient();
  const { familyToken, familyId, userId } = query as unknown as IQuery;

  // User is trying to log in
  if (familyToken && familyId && userId) {
    try {
      deleteCookie(SESSION_COOKIE_KEY, { req, res });

      const { data: sessionData } = await login({
        user_id: Number.parseInt(userId),
        family_id: Number.parseInt(familyId),
        family_token: familyToken
      });

      if (sessionData.data?.attributes?.token) {
        setCookie(SESSION_COOKIE_KEY, sessionData.data.attributes.token, { req, res, maxAge: 60 * 60 * 24 });
      }
    } catch (e) {}
  }

  // Prefetch the user's session data, if a valid token has been set
  if (hasCookie(SESSION_COOKIE_KEY, { req, res })) {
    await queryClient.prefetchQuery(
      getSessionDataKey,
      async () =>
        (
          await getSessionData(
            {},
            {
              headers: {
                Authorization: "Bearer " + getCookie(SESSION_COOKIE_KEY, { req, res })
              }
            }
          )
        ).data
    );
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    }
  };
};

Login.displayName = "Login";

export default Login;
